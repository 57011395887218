import { lazy, Fragment } from "react";
import { Row, Col } from "antd";
import Fade from "react-reveal/Fade";

import * as S from "./styles";

const Image = lazy(() => import("../../common/Image"));
const Container = lazy(() => import("../../common/Container"));

const Footer = () => {
  
  return (
    <Fragment>
      <Fade bottom>
        <S.Extra >
          <Container border="true" >
            <Row type="flex" justify="center" align="middle" style={{backgroundColor:"#0c0d0e"}}>
              <S.NavLink to="/">
                <S.LogoContainer >
                  {/* <Image src="ICON.png" aria-label="homepage" width="24px" height="24px" /> */}
                  <p>www.playcodex.com</p>
                </S.LogoContainer>
              </S.NavLink>
            </Row>
            <Row type="flex" justify="center" align="middle" style={{backgroundColor:"#0c0d0e"}}>
              <S.NavLink to="/privacy-policy">
                <S.LogoContainer >
                  <p>Privacy Policy</p>
                </S.LogoContainer>
              </S.NavLink>
            </Row>
            <Row type="flex" justify="center" align="middle" style={{backgroundColor:"#0c0d0e"}}>
              <S.NavLink to="/terms-of-service">
                <S.LogoContainer >
                  <p>Terms Of Service</p>
                </S.LogoContainer>
              </S.NavLink>
            </Row>
          </Container>
        </S.Extra>
      </Fade>
    </Fragment>
  );
};

export default Footer;
