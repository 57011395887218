import { lazy } from "react";
import GamesContent from "../../content/GamesContent.json";


const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const Games = () => {
  return (
    <div className="App">
      <Container>
        <ScrollToTop />
        {GamesContent.map((game, i) => (
          <ContentBlock 
            type="game-left"
            title={game.title}
            content={game.textParagraphs.reduce((acc, val) => acc + "\n" + val)}
            icons={game.images}
            id={`games-${i}`}
            badge={game.badge}>
          </ContentBlock>
        ))}
      </Container>
    </div>
  );
};

export default Games;
