
import Slide from "react-reveal/Slide";
import * as S from "./styles";

const Header = () => {
  
  return (
    <S.Header>
      <S.Container>
        <header id="TopHead" >
            <div >
              <Slide left>
                <img src="img/orange_narrow_1.png" widht="128px" height="32px" alt=""/>
              </Slide>
              <Slide right>
                <img align="right" src="img/orange_narrow_2.png" widht="128px" height="32px" alt=""/>
              </Slide>
            </div>
            <div className="App-header">
              <h1>PLAYCODEX</h1>
            </div>
            <div>
              <Slide left>
                <img src="img/orange_narrow_1_opp.png" widht="128px" height="32px" alt=""/>
              </Slide>
              <Slide right>
                <img align="right" src="img/orange_narrow_2_opp.png" widht="128px" height="32px" alt=""/>
              </Slide>
            </div>
        </header>
      </S.Container>
    </S.Header>
  );
};

export default Header;
