import { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";
import useWindowSize from "@rooks/use-window-size";
import React from "react";
import { lazy } from "react";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const TEXTS = ["EASY TO PLAY", "HARD TO MASTER"];

const Home = () => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="App">
      <Container>
        <ScrollToTop />
        <header className="TransitionText">
            <div>
              <TextTransition text={" " + TEXTS[index % TEXTS.length]} springConfig={presets.stiff} />
            </div>
        </header>
        <br></br>
        <div class="HomePage">
          <div align="center" >
            <img id="shooter" src="img/shooter3.png"/>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Home;
