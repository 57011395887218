import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Footer, Header, Navbar } from "./components";
import { Games, Contact, Blog, Home, PrivacyPolicy, TermsOfService, PrivacyPolicyMain, TermsOfServiceMain } from "./pages";
import "./App.css";
import "antd/dist/antd.css";

function App() {
  return (
    <div>
      <Router>
        <div className="App">
        <Navbar />
        </div>
        <Header />
          <Switch>
            {/* <Route path="/contact">
              <Contact />
            </Route> */}
            {/* <Route path="/blog">
              <Blog />
            </Route> */}
            <Route path="/games">
              <Games />
            </Route>
            <Route path="/privacy-policy/:appCode">
              <PrivacyPolicy/>
            </Route>
            <Route path="/terms-of-service/:appCode">
              <TermsOfService/>
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicyMain/>
            </Route>
            <Route path="/terms-of-service">
              <TermsOfServiceMain/>
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
