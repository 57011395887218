import { lazy } from "react";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const PrivacyPolicyMain = () => {

  return (
    <div >
      <div></div>
      <Container>
        <div align="middle" style={{ backgroundColor:"#ffa101", margin:"50px 0px 0px 0px"}}>
          <h3>Privacy Policy :</h3>
        </div>
        <br></br>
        <ScrollToTop />
        <ul align="middle" style={{backgroundColor:"#0c0d0e", margin:"0px 0px 100px 0px"}}>
          <li>
            <a style={{color:"#ffa101", fontSize:"1rem"}} href="/privacy-policy/a2s2k" target="_blank" rel="noreferrer">
              Multiplayer Shooting Arena A2S2K
            </a>
            
          </li>
          <br></br>
          <li>
            <a style={{color:"#ffa101", fontSize:"1rem"}}  href="/privacy-policy/sm" target="_blank" rel="noreferrer">
              Shooting Master
            </a>
          </li>
          <br></br>
          <li>
            <a style={{color:"#ffa101", fontSize:"1rem"}} href="/privacy-policy/sm2" target="_blank" rel="noreferrer">
              Shooting Master 2
            </a>
          </li>
          <br></br>
          <li>
            <a style={{color:"#ffa101", fontSize:"1rem"}}  href="/privacy-policy/wfz" target="_blank" rel="noreferrer">
              Warfare Z Gold
            </a>
          </li>
        </ul>
      </Container>
    </div>
  );
};
export default PrivacyPolicyMain;
