import { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  Home as HomeIcon,
  SportsEsports as GamesIcon,
  LibraryBooks as BlogIcon,
  ContactMail as ContactIcon,
  Menu as MenuIcon
} from "@material-ui/icons";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid orange",
    backgroundColor: "#000000",
    color: "cyan",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      //   color: theme.palette.common.white,
      // },
    },
  },
}))(MenuItem);

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        style={{ backgroundColor:"#16171a" }}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
      >
      <MenuIcon style={{ color: "orange" }} />
      </Button>
      <StyledMenu id="customized-menu" 
      anchorEl={anchorEl} 
      keepMounted open={Boolean(anchorEl)} 
      onClick={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <HomeIcon style={{ color: "#aed4d6" }} fontSize="small" />
          </ListItemIcon>
          <Link className="App-link" to="/">
            HOME
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <GamesIcon style={{ color: "#aed4d6" }} fontSize="small" />
          </ListItemIcon>
          <Link className="App-link" to="/games">
            GAMES
          </Link>
        </StyledMenuItem>
        {/* <StyledMenuItem>
          <ListItemIcon>
            <BlogIcon style={{ color: "#aed4d6" }} fontSize="small" />
          </ListItemIcon>
          <Link className="App-link" to="/blog">
            BLOG
          </Link>
        </StyledMenuItem> */}
        {/* <StyledMenuItem>
          <ListItemIcon>
            <ContactIcon style={{ color: "#aed4d6" }} fontSize="small" />
          </ListItemIcon>
          <Link className="App-link" to="/contact">
            CONTACT
          </Link>
        </StyledMenuItem> */}
      </StyledMenu>
    </div>
  );
};

export default Navbar;
